import React, { useState } from "react";
import "./Login.css";  // Import the CSS file

const Login = ({ onLogin }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [rememberMe, setRememberMe] = useState(false);

  // Handle Login button click
  const handleLogin = () => {
    if (!email || !password || !role) {
      setErrorMessage("Please fill in all fields and select a role.");
    } else {
      // Perform login logic
      onLogin(role);
      // Optionally handle rememberMe here to persist login details
      if (rememberMe) {
        localStorage.setItem("userRole", role);
      }
      setErrorMessage("");  // Clear any existing error message
      alert("Login successful!");
    }
  };

  return (
    <div className="login-container">
      <div className="login-form">
        <h2>Login</h2>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter your password"
            required
          />
        </div>
        <div>
          <label>Select Role:</label>
          <select
            value={role}
            onChange={(e) => setRole(e.target.value)}
            className="select-field"
          >
            <option value="">Select Role</option>
            <option value="admin">Admin</option>
            <option value="client">Client</option>
            <option value="tutor">Tutor</option>
          </select>
        </div>

        <div className="remember-me">
          <input
            type="checkbox"
            checked={rememberMe}
            onChange={(e) => setRememberMe(e.target.checked)}
          />
          <label>Remember Me</label>
        </div>

        <button className="login-button" onClick={handleLogin}>
          Login
        </button>

        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </div>
    </div>
  );
};

export default Login;
