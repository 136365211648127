import React, { useState } from "react";
import "./Contact.css";

const Contact = () => {
  const [formData, setFormData] = useState({
    studentName: "",
    studentClass: "",
    phone: "",
    email: "",
    tutorPreference: "",
    timeSlot: "",
    subjects: "",
    address: "",
    heardFrom: "",
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);
    setSuccess(false);

    try {
      // Updated URL to point to the live server
      const response = await fetch("https://ihtbackend.onrender.com/send-contact-form", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        setSuccess(true);
        setFormData({
          studentName: "",
          studentClass: "",
          phone: "",
          email: "",
          tutorPreference: "",
          timeSlot: "",
          subjects: "",
          address: "",
          heardFrom: "",
        });
      } else {
        setError(data.message || "An error occurred while submitting the form.");
      }
    } catch (err) {
      setError("Failed to send the form. Please try again later.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="contact-form">
      <h2>Contact Us</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="text"
          name="studentName"
          value={formData.studentName}
          onChange={handleChange}
          placeholder="Your Name"
          required
        />
        <input
          type="text"
          name="studentClass"
          value={formData.studentClass}
          onChange={handleChange}
          placeholder="Class"
          required
        />
        <input
          type="text"
          name="phone"
          value={formData.phone}
          onChange={handleChange}
          placeholder="Phone"
          required
        />
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          placeholder="Email"
          required
        />
        <input
          type="text"
          name="tutorPreference"
          value={formData.tutorPreference}
          onChange={handleChange}
          placeholder="Tutor Preference"
        />
        <input
          type="text"
          name="timeSlot"
          value={formData.timeSlot}
          onChange={handleChange}
          placeholder="Preferred Time Slot"
        />
        <input
          type="text"
          name="subjects"
          value={formData.subjects}
          onChange={handleChange}
          placeholder="Subjects"
        />
        <input
          type="text"
          name="address"
          value={formData.address}
          onChange={handleChange}
          placeholder="Address"
        />
        <input
          type="text"
          name="heardFrom"
          value={formData.heardFrom}
          onChange={handleChange}
          placeholder="How Did You Hear About Us?"
        />
        <button type="submit" disabled={isSubmitting}>
          {isSubmitting ? "Submitting..." : "Submit"}
        </button>
      </form>

      {success && <p>Form submitted successfully! We'll get back to you soon.</p>}
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default Contact;
