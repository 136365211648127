// Updated Home.js
import React from "react";
import { Link } from "react-router-dom";  // Import Link for navigation
import Nave from "./Nave";
import About from "./About";
import Header from "./Header";
import Request from "./Request";
import Footer from "./Footer";
import TutorSearch from "./components/TutorSearch";  // Adjust path if necessary

const Home = () => {
  return (
    <div className="home-container">
      <Nave />
      <hr />
      <hr />
      <hr />
      <Header />
      <div className="auth-links">
      <Link to="/login">Login</Link> | <Link to="/signup">Sign Up</Link>
    </div>
      <div className="section-border" style={{ marginBottom: "50px" }}>
        <TutorSearch />
      </div>
      <div className="section-border" style={{ marginBottom: "100px" }}>
        <About />
      </div>
      <div className="section-border" style={{ marginBottom: "50px" }}>
        <Request />
      </div>
     
      <Footer />
    </div>
  );
};

export default Home;
