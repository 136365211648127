import React from 'react';
import './Programs.css'; // Ensure this CSS file exists
import Nave from "./Nave.js";
// Import images
import specialChildCareImg from './images/cheerful-boy-disability-rehabilitation-center-260nw-524271736.webp';
import childMindsGrowImg from './images/images.jpeg';
import childCareImg from './images/images (8).jpeg';
import teachingChildrenImg from './images/download (2).jpeg';
import Footer from './Footer.js';

const programsData = [
  {
    title: 'Special Child Care',
    description: 'A dedicated program for special needs children to help them thrive and develop.',
    image: specialChildCareImg, // Use imported image
  },
  {
    title: 'Child Minds Grow',
    description: 'Engaging activities and learning experiences designed to stimulate young minds.',
    image: childMindsGrowImg, // Use imported image
  },
  {
    title: 'Child Care Programs',
    description: 'Comprehensive child care solutions focusing on nurturing and education.',
    image: childCareImg, // Use imported image
  },
  {
    title: 'Teaching Children',
    description: 'Innovative teaching strategies to ensure children learn effectively and enjoyably.',
    image: teachingChildrenImg, // Use imported image
  },
];

const Programs = () => {

  return (
    <>
    <Nave/>
    <section className="programs_section">
      <div className="container">
        <div className="heading_container">
          <h2>Our Child Care Programs</h2>
          <p>Explore our specialized programs designed for the holistic development of children.</p>
        </div>
        <div className="programs_content">
          {programsData.map((program, index) => (
            <div className="program" key={index}>
              <img src={program.image} alt={program.title} />
              <h3>{program.title}</h3>
              <p>{program.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
    <> <Footer/></>
    </>
  );
};

export default Programs;
