import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneAlt,
  faCommentDots,
  faSearch,
  faBars,
} from "@fortawesome/free-solid-svg-icons";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Nave.css";
import { Link } from "react-router-dom";
import logo from "./images/Blue and Orange Flat Illustrative Education Logo (7).png"; // Add your image path here

const Nave = () => {
  return (
    <div className="navbar-wrapper w-100">
      {/* Full-width Navbar */}
      <div className="navbar-container w-100 px-0">
        <nav className="navbar navbar-light navbar-expand-xl py-3 w-100">
          <a href="index.html" className="navbar-brand">
            {/* Replace the text with an image */}
            <img
              src={logo}
              alt="Indias Home Tuition"
              style={{ width: "150px", height: "auto" }} // Adjust the width and height as per your needs
            />
          </a>
          <button
            className="navbar-toggler py-2 px-3"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <FontAwesomeIcon icon={faBars} className="text-primary" />
          </button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav mx-auto">
              <Link to="/" className="nav-item nav-link">
                Home
              </Link>
              <Link to="/About2" className="nav-item nav-link">
                About
              </Link>
              <Link to="/Services" className="nav-item nav-link">
                Services
              </Link>
              <Link to="/Programs" className="nav-item nav-link">
                Programs
              </Link>

              <div className="nav-item dropdown">
                <Link
                  to="#"
                  className="nav-link dropdown-toggle active"
                  data-bs-toggle="dropdown"
                >
                  Pages
                </Link>
                <div className="dropdown-menu m-0 bg-secondary rounded-0">
                  <Link to="/blog" className="dropdown-item">
                    Our Blog
                  </Link>
                  <Link to="/team" className="dropdown-item">
                    Our Team
                  </Link>
                  <Link to="/testimonial" className="dropdown-item">
                    Testimonial
                  </Link>
                  <Link to="/404" className="dropdown-item active">
                    404 Page
                  </Link>
                </div>
              </div>
              <Link to="/Contact" className="nav-item nav-link">
                Contact
              </Link>
            </div>
            <div className="d-flex me-4">
              <div
                id="phone-tada"
                className="d-flex align-items-center justify-content-center"
              >
                <Link
                  to="#"
                  className="position-relative wow tada"
                  data-wow-delay=".9s"
                >
                  <FontAwesomeIcon
                    icon={faPhoneAlt}
                    className="text-primary fa-2x me-4"
                  />
                  <div
                    className="position-absolute"
                    style={{ top: "-7px", left: "20px" }}
                  >
                    <span>
                      <FontAwesomeIcon
                        icon={faCommentDots}
                        className="text-secondary"
                      />
                    </span>
                  </div>
                </Link>
              </div>
              <div className="d-flex flex-column pe-3 border-end border-primary">
                <span className="contact-question">Have any questions?</span>
                <a href="#">
                  <span className="contact-number">Free: + 91-9079177216</span>
                </a>
              </div>
            </div>
            <button
              className="btn-search btn btn-primary btn-md-square rounded-circle"
              data-bs-toggle="modal"
              data-bs-target="#searchModal"
            >
              <FontAwesomeIcon icon={faSearch} className="text-white" />
            </button>
          </div>
        </nav>
      </div>
    </div>
  );
};

export default Nave;
