import React, { useState } from "react";
import axios from "axios";
import "./TutorSearch.css";  // Ensure you create and style this CSS file

const TutorSearch = () => {
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [location, setLocation] = useState("");
  const [timeSlot, setTimeSlot] = useState("");
  const [tutors, setTutors] = useState([]);
  const [message, setMessage] = useState("");

  const handleSearch = async () => {
    // Clear the previous message before starting a new search
    setMessage("");
    try {
      // Make a POST request to your backend API
      const response = await axios.post("http://localhost:5000/search-tutor", {
        selectedClass,
        selectedSubject,
        location,
        timeSlot
      });

      // Check if there are any tutors available
      if (response.data && response.data.tutors && response.data.tutors.length > 0) {
        setTutors(response.data.tutors);
      } else {
        setTutors([]);  // No tutors found, clear the list
        setMessage("No tutors found for your criteria.");
      }
    } catch (error) {
      // Handle errors appropriately
      setTutors([]);
      if (error.response && error.response.status === 404) {
        setMessage("No tutors found for your criteria.");
      } else {
        setMessage("Error occurred while searching for tutors. Please try again.");
      }
    }
  };

  return (
    <div className="tutor-search">
      <h2>Find a Tutor</h2>

      {/* Class Selection */}
      <div>
        <label>Class:</label>
        <select value={selectedClass} onChange={(e) => setSelectedClass(e.target.value)}>
          <option value="">Select Class</option>
          <option value="Class 10">Class 10</option>
          <option value="Class 12">Class 12</option>
          {/* Add more classes as needed */}
        </select>
      </div>

      {/* Subject Selection */}
      <div>
        <label>Subject:</label>
        <select value={selectedSubject} onChange={(e) => setSelectedSubject(e.target.value)}>
          <option value="">Select Subject</option>
          <option value="Math">Math</option>
          <option value="Science">Science</option>
          {/* Add more subjects as needed */}
        </select>
      </div>

      {/* Location Selection */}
      <div>
        <label>Location:</label>
        <select value={location} onChange={(e) => setLocation(e.target.value)}>
          <option value="">Select Location</option>
          <option value="Delhi">Delhi</option>
          <option value="Mumbai">Mumbai</option>
          <option value="Bangalore">Bangalore</option>
          {/* Add more locations as needed */}
        </select>
      </div>

      {/* Time Slot Selection */}
      <div>
        <label>Preferred Time Slot:</label>
        <select value={timeSlot} onChange={(e) => setTimeSlot(e.target.value)}>
          <option value="">Select Time Slot</option>
          <option value="Morning">Morning</option>
          <option value="Afternoon">Afternoon</option>
          <option value="Evening">Evening</option>
          {/* Add more time slots as needed */}
        </select>
      </div>

      {/* Search Button */}
      <button onClick={handleSearch}>Search Tutor</button>

      {/* Displaying error message or no results found */}
      {message && <p className="message">{message}</p>}

      {/* Display the list of tutors if available */}
      {tutors.length > 0 && (
        <div>
          <h3>Available Tutors:</h3>
          <ul>
            {tutors.map((tutor) => (
              <li key={tutor._id}>
                <p><strong>Name:</strong> {tutor.name}</p>
                <p><strong>Experience:</strong> {tutor.experience} years</p>
                <p><strong>Contact:</strong> {tutor.contact}</p>
                <p><strong>Location:</strong> {tutor.location}</p>
                <p><strong>Available Time Slot:</strong> {tutor.timeSlot}</p>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default TutorSearch;
