import React from "react";
import "./Footer.css"; // Import your custom CSS if needed
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTwitter,
  faFacebook,
  faInstagram,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <footer className="ftco-footer ftco-bg-dark ftco-section">
      <div className="container">
        <div className="row mb-5">
          <div className="col-md-6 col-lg-3">
            <div className="ftco-footer-widget mb-5">
              <h2 className="ftco-heading-2">Address</h2>
              <div className="block-23 mb-3">
                <ul>
                  <li>
                    <span className="icon icon-map-marker"></span>
                    <span className="text">
                      Uttam Nagar, West Delhi, INDIA.
                    </span>
                  </li>
                  <li>
                    <a href="tel:+919079177216">
                      <span className="icon icon-phone"></span>
                      <span className="text">+91-9079177216</span>
                    </a>
                  </li>
                  <li>
                    <a href="mailto:indiashometuition@gmail.com">
                      <span className="icon icon-envelope"></span>
                      <span className="text">
                        indiashometuition@gmail.com
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          {/* Links and Social Media Section */}
          <div className="col-md-6 col-lg-3">
            <div className="ftco-footer-widget mb-5 ml-md-4">
              <h2 className="ftco-heading-2">Links</h2>
              <ul className="list-unstyled">
                <li><a href="#"><span className="ion-ios-arrow-round-forward mr-2"></span> Home</a></li>
                <li><a href="#"><span className="ion-ios-arrow-round-forward mr-2"></span> About</a></li>
                <li><a href="#"><span className="ion-ios-arrow-round-forward mr-2"></span> Services</a></li>
                <li><a href="#"><span className="ion-ios-arrow-round-forward mr-2"></span> Departments</a></li>
                <li><a href="#"><span className="ion-ios-arrow-round-forward mr-2"></span> Contact</a></li>
              </ul>
            </div>
          </div>

          <div className="col-md-6 col-lg-3">
            <div className="ftco-footer-widget mb-5">
              <h2 className="ftco-heading-2">Subscribe Us!</h2>
              <form action="#" className="subscribe-form">
                <div className="form-group">
                  <input type="text" className="form-control mb-2 text-center" placeholder="Enter email address" />
                  <input type="submit" value="Subscribe" className="form-control submit px-3" />
                </div>
              </form>
            </div>
            <div className="ftco-footer-widget mb-5">
              <h2 className="ftco-heading-2 mb-0">Connect With Us</h2>
              <ul className="ftco-footer-social list-unstyled float-md-left float-lft mt-3">
                <li className="ftco-animate"><a href="https://x.com/INDsHomeTution?t=WfqMvWO5A9QvnGr7YWd4mQ&s=09"><FontAwesomeIcon icon={faTwitter} /></a></li>
                <li className="ftco-animate"><a href="https://www.facebook.com/share/h1EejeDqpxZ1iZag/?mibextid=qi2Omg"><FontAwesomeIcon icon={faFacebook} /></a></li>
                <li className="ftco-animate"><a href="https://www.instagram.com/indiashometuition?igsh=MTA5YzB2dmFjbjJvNw=="><FontAwesomeIcon icon={faInstagram} /></a></li>
                <li className="ftco-animate"><a href="https://youtube.com/@indiashometuition?si=v_P94wZNl1X6zO0o"><FontAwesomeIcon icon={faYoutube} /></a></li>
              </ul>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-12 text-center">
            <p>
              &copy; {new Date().getFullYear()} All rights reserved by{" "}
              <a href="https://www.instagram.com/indiashometuition?igsh=MTA5YzB2dmFjbjJvNw==" target="_blank" rel="noopener noreferrer">@IndiasHomeTuition</a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
