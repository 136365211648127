import React from "react";

const TutorDashboard = () => {
  return (
    <div>
      <h2>Tutor Dashboard</h2>
      <p>Welcome to your dashboard, Tutor!</p>
    </div>
  );
};

export default TutorDashboard;
