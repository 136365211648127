import React from "react";
import "./Header.css"; // Assuming you have a CSS file for styling
import "bootstrap/dist/css/bootstrap.min.css"; // Ensure Bootstrap is installed and imported
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Ensure Bootstrap JavaScript bundle is imported
import { Link } from "react-router-dom";
import sliderImage1 from "./images/cheerful-boy-disability-rehabilitation-center-260nw-524271736.webp"; 
import sliderImage2 from "./images/slider-bg.jpg"; 
import sliderImage3 from "./images/bg_5.jpg"; 

const Header = () => {
  const sliderData = [
    {
      image: sliderImage1,
      title: "A Perfect Learning Center",
      subtitle: "For Your Kids",
      description: "Greatness begins with the right guidance – discover a tutor who will awaken your child's true potential.",
    },
    {
      image: sliderImage2,
      title: "Nurturing Young Minds",
      subtitle: "Unlock Their Potential",
      description: "Every child is a different kind of flower, and all together, they make this world a beautiful garden.",
    },
    {
      image: sliderImage3,
      title: "Empowering Futures",
      subtitle: "Shape Their Success",
      description: "Education is not preparation for life; education is life itself.",
    },
  ];

  return (
    <section className="slider_section position-relative">
      <div className="container-fluid"> {/* Full width for slider */}
        <div
          id="carouselExampleIndicators"
          className="carousel slide"
          data-bs-ride="carousel" // Bootstrap 5 syntax
          data-bs-interval="5000" // Set interval for sliding
        >
          <div className="carousel-indicators">
            {sliderData.map((_, index) => (
              <button
                type="button"
                data-bs-target="#carouselExampleIndicators"
                data-bs-slide-to={index}
                className={index === 0 ? "active" : ""}
                aria-current={index === 0 ? "true" : ""}
                aria-label={`Slide ${index + 1}`}
                key={index}
              ></button>
            ))}
          </div>

          <div className="carousel-inner">
            {sliderData.map((item, index) => (
              <div
                key={index}
                className={`carousel-item ${index === 0 ? "active" : ""}`}
                style={{ backgroundImage: `url(${item.image})` }} // Dynamic background image
              >
                <div className="row h-100 justify-content-center align-items-center">
                  <div className="col-md-6 text-center">
                    <div className="detail-box">
                      <h1>
                        {item.title} <br />
                        <span>{item.subtitle}</span>
                      </h1>
                      <p>{item.description}</p>
                      <div className="btn-box">
                        <Link to="/About2" className="btn-1">
                          Read More
                        </Link>
                        <Link to="/Contact" className="btn-2">
                          Contact us
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Carousel controls */}
          <a
            className="carousel-control-prev"
            href="#carouselExampleIndicators"
            role="button"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="sr-only">Previous</span>
          </a>
          <a
            className="carousel-control-next"
            href="#carouselExampleIndicators"
            role="button"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="sr-only">Next</span>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Header;
