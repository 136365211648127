import React, { useState } from "react";
import axios from "axios";
import "./Signup.css";

const Signup = ({ onSignup }) => {
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("client");

  const [tutorDetails, setTutorDetails] = useState({
    specialization: "",
    salaryExpectation: "",
    subjects: "",
  });

  const [clientDetails, setClientDetails] = useState({
    classSelection: "",
    subject: "",
    location: "",
    preferredTimeSlot: "",
    availability: "",
  });

  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(""); // Clear previous errors

    // Validate required fields
    if (!username || !email || !password) {
      setError("Please fill out all the required fields.");
      return;
    }

    // Validate tutor details
    if (
      role === "tutor" &&
      (!tutorDetails.specialization ||
        !tutorDetails.salaryExpectation ||
        !tutorDetails.subjects)
    ) {
      setError(
        "Please provide specialization, salary expectation, and subjects for Tutor."
      );
      return;
    }

    // Validate client details
    if (
      role === "client" &&
      (!clientDetails.classSelection ||
        !clientDetails.subject ||
        !clientDetails.location ||
        !clientDetails.preferredTimeSlot ||
        !clientDetails.availability)
    ) {
      setError(
        "Please provide class, subject, location, preferred time slot, and availability for Client."
      );
      return;
    }

    const formData = {
      username,
      email,
      password,
      role,
      ...(role === "tutor" ? tutorDetails : clientDetails),
    };

    try {
      const response = await axios.post(
        "https://ihtbackend.onrender.com/auth/signup",  // Ensure this is the correct endpoint
        formData,
        {
          headers: { "Content-Type": "application/json" },
        }
      );

      if (response.data.message) {
        // Assuming 'message' will be provided on success or failure
        alert(response.data.message);
        onSignup(formData);
      } else {
        setError("Error during signup, please try again.");
      }
    } catch (error) {
      console.error(error);
      setError("Error during signup, please try again.");
    }
  };

  return (
    <div className="signup-container">
      <h2>Sign Up</h2>
      {error && <p className="error-message">{error}</p>}
      <form onSubmit={handleSubmit}>
        <div>
          <label>Username:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div>
          <label>Role:</label>
          <select value={role} onChange={(e) => setRole(e.target.value)}>
            <option value="client">Client</option>
            <option value="tutor">Tutor</option>
          </select>
        </div>

        {role === "tutor" && (
          <>
            <div>
              <label>Specialization:</label>
              <input
                type="text"
                value={tutorDetails.specialization}
                onChange={(e) =>
                  setTutorDetails({
                    ...tutorDetails,
                    specialization: e.target.value,
                  })
                }
                required
              />
            </div>
            <div>
              <label>Salary Expectation:</label>
              <input
                type="number"
                value={tutorDetails.salaryExpectation}
                onChange={(e) =>
                  setTutorDetails({
                    ...tutorDetails,
                    salaryExpectation: e.target.value,
                  })
                }
                required
              />
            </div>
            <div>
              <label>Subjects You Can Teach:</label>
              <input
                type="text"
                value={tutorDetails.subjects}
                onChange={(e) =>
                  setTutorDetails({ ...tutorDetails, subjects: e.target.value })
                }
                required
              />
            </div>
          </>
        )}

        {role === "client" && (
          <>
            <div>
              <label>Class:</label>
              <select
                value={clientDetails.classSelection}
                onChange={(e) =>
                  setClientDetails({
                    ...clientDetails,
                    classSelection: e.target.value,
                  })
                }
                required
              >
                <option value="">Select Class</option>
                <option value="1">Class 1</option>
                <option value="2">Class 2</option>
              </select>
            </div>
            <div>
              <label>Subject:</label>
              <select
                value={clientDetails.subject}
                onChange={(e) =>
                  setClientDetails({
                    ...clientDetails,
                    subject: e.target.value,
                  })
                }
                required
              >
                <option value="">Select Subject</option>
                <option value="math">Math</option>
                <option value="english">English</option>
              </select>
            </div>
            <div>
              <label>Location:</label>
              <input
                type="text"
                value={clientDetails.location}
                onChange={(e) =>
                  setClientDetails({
                    ...clientDetails,
                    location: e.target.value,
                  })
                }
                required
              />
            </div>
            <div>
              <label>Preferred Time Slot:</label>
              <input
                type="text"
                value={clientDetails.preferredTimeSlot}
                onChange={(e) =>
                  setClientDetails({
                    ...clientDetails,
                    preferredTimeSlot: e.target.value,
                  })
                }
                required
              />
            </div>
            <div>
              <label>Availability:</label>
              <input
                type="text"
                value={clientDetails.availability}
                onChange={(e) =>
                  setClientDetails({
                    ...clientDetails,
                    availability: e.target.value,
                  })
                }
                required
              />
            </div>
          </>
        )}

        <button type="submit">Sign Up</button>
      </form>
    </div>
  );
};

export default Signup;
