// Updated App.js
import React, { useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

// Public Routes Components
import Home from "./Home";
import About2 from "./About2";
import Contact from "./Contact";
import Services from "./Services";
import Programs from "./Programs";

// Auth Routes and Dashboard Components
import Login from "./components/Login";  // Ensure path is correct
import Signup from "./components/Signup";  // Added Signup route
import Dashboard from "./Dashboard";  // Admin Dashboard
import ClientDashboard from "./components/ClientDashboard";  // Client Dashboard
import TutorDashboard from "./components/TutorDashboard";  // Tutor Dashboard

const App = () => {
  const [userRole, setUserRole] = useState(null);  // User's role, initially null

  // Login function to set user role
  const login = (role) => {
    setUserRole(role);  // Set role when user logs in
  };

  // Signup function to set user role
  const signup = (role) => {
    setUserRole(role);  // Set role when user signs up
  };

  // Protected Route to control access based on role
  const ProtectedRoute = ({ element, requiredRole }) => {
    return userRole === requiredRole ? element : <Navigate to="/login" />;
  };

  return (
    <BrowserRouter>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/About2" element={<About2 />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/Programs" element={<Programs />} />
        <Route path="/Services" element={<Services />} />

        {/* Login and Signup Routes */}
        <Route path="/login" element={<Login onLogin={login} />} />
        <Route path="/signup" element={<Signup onSignup={signup} />} />

        {/* Protected Routes */}
        {/* Admin Dashboard */}
        <Route
          path="/admin-dashboard"
          element={<ProtectedRoute element={<Dashboard />} requiredRole="admin" />}
        />

        {/* Client Dashboard */}
        <Route
          path="/client-dashboard"
          element={<ProtectedRoute element={<ClientDashboard />} requiredRole="client" />}
        />

        {/* Tutor Dashboard */}
        <Route
          path="/tutor-dashboard"
          element={<ProtectedRoute element={<TutorDashboard />} requiredRole="tutor" />}
        />

        {/* Services (only accessible by admin) */}
        <Route
          path="/Services"
          element={<ProtectedRoute element={<Services />} requiredRole="admin" />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
