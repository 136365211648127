import React from "react";

const ClientDashboard = () => {
  return (
    <div>
      <h2>Client Dashboard</h2>
      <p>Welcome to your dashboard, Client!</p>
    </div>
  );
};

export default ClientDashboard;
