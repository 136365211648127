import React from 'react';
import './Request.css'; // Ensure this imports your updated CSS
import { Link } from 'react-router-dom'; // Only if using React Router

const Request = () => {
  return (
    <div className="request-video">
      <div className="heading-section">
        <h2>Our Work Video</h2>
        <p className="subheading">Watch this video to know more about our services and expertise</p>
      </div>
      <div className="video-container">
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/7TyHfAJyQCk" // Corrected embed link
          title="Work Video"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <Link to="/contact" className="btn">Contact Us for More Details</Link> {/* Internal link */}
    </div>
  );
};

export default Request;
