import React from "react";
import "./About2.css";
import logo from "./images/about.jpg";
import Nave from "./Nave";
import Footer from "./Footer";


const About2 = () => {
  return (
    <>
      <Nave/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <br/>
      <section className="about_section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-6">
              <div className="img-box">
                <img src={logo} alt="About Us" />
              </div>
            </div>
            <div className="col-md-5 col-lg-4">
              <div className="detail-box">
                <div className="heading_container">
                  <h2>A Few Words About Us</h2>
                </div>
                <p>
                   provide expert home tutor services across all of India,
                  ensuring personalized education tailored to every student's
                  needs. Our highly qualified tutors specialize in a variety of
                  subjects, offering one-on-one guidance that fosters academic
                  excellence. Whether it's school-level education or competitive
                  exam preparation, we have tutors to match every requirement.
                  With flexible scheduling, we bring quality education right to
                  your doorstep, ensuring convenience for both students and
                  parents. Our tutors focus on building strong foundational
                  knowledge and boosting confidence in learners. We cater to
                  students of all age groups and educational boards. Experience a
                  customized learning journey with our dedicated home tutor
                  services, available nationwide.
                </p>
                <div>
                  <a href="/">Read More</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
     <><Footer/></>
     </>
  );
};

export default About2;
