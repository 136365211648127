import React from "react";
import "./Services.css"; // Create a CSS file for styling
import tutoringImage1 from "./images/image_2.jpg"; // Replace with your actual image paths
import tutoringImage2 from "./images/image_3.jpg"; // Replace with your actual image paths
import tutoringImage3 from "./images/image_4.jpg"; // Replace with your actual image paths
import Nave from "./Nave.js";
import Footer from "./Footer.js";
const Services = () => {
  return (
    <>
    <Nave/>
    <section className="services_section">
      <div className="container">
        <div className="heading_container">
          <h2>Our Home Tuition Services</h2>
          <p>
            We provide expert home tuition services across India, ensuring personalized education tailored to each student's unique needs.
          </p>
        </div>
        <div className="services_content">
          <div className="service">
            <img src={tutoringImage1} alt="Personalized Tutoring" />
            <h3>Personalized Tutoring</h3>
            <p>
              Our qualified tutors create customized lesson plans to cater to individual learning styles, ensuring effective and engaging lessons.
            </p>
          </div>
          <div className="service">
            <img src={tutoringImage2} alt="Flexible Scheduling" />
            <h3>Flexible Scheduling</h3>
            <p>
              We understand the importance of flexibility. Our tutors work around your schedule to provide lessons at the most convenient times for you.
            </p>
          </div>
          <div className="service">
            <img src={tutoringImage3} alt="Subjects Covered" />
            <h3>Subjects Covered</h3>
            <p>
              We offer tutoring for a wide range of subjects, from mathematics and science to languages and arts, ensuring comprehensive support for all students.
            </p>
          </div>
        </div>
      </div>
    </section>
    <> <Footer/></>
    </>
  );
};

export default Services;
